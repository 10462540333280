<template>
  <div class="w-full justify-center">
    <h3 class="mt-2" style="color: #1f1f1f">{{ $t("PrescriptionDetails") }}</h3>

    <div div class="vx-row w-full mt-5">
      <div div class="vx-col lg:w-1/4 w-full">
        <vs-input
          data-vv-validate-on="blur"
          name="Title"
          :label="$t('Title')+'*'"
          class="w-full"
          icon-no-border
          v-model="doctorModel.TitleEN"
          icon-after="true"
        />
      </div>

      <div div class="vx-col lg:w-3/4 w-full">
        <vs-input
          data-vv-validate-on="blur"
          :label="$t('FullName')+'*'"
          name="FullName"
          class="w-full"
          icon-no-border
          v-model="doctorModel.FullName"
          icon-after="true"
        />
      </div>
    </div>

    <div div class="w-full mt-3">
      <vs-input
        data-vv-validate-on="blur"
        name="Address"
        :label="$t('Address')"
        class="w-full"
        icon-no-border
        v-model="doctorModel.Address"
        icon-after="true"
      />
    </div>

    <h3 style="color: #1f1f1f" class="mt-5">{{ $t("Licences") }}</h3>
    <div div class="vx-row w-full justify-center mt-1">
      <div class="vx-col lg:w-1/2 w-full">
        <!-- Country -->
        <label class="vs-input--label">{{ $t("Country") }}</label>
        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          data-vv-validate-on="blur"
          label="Name"
          name="Country"
          label-placeholder="Country"
          :placeholder="$t('Country')"
          class="w-full"
          v-model="LicenseModel.Country"
          :options="countries"
          icon-after="true"
          :reduce="(Country) => Country"
        />
      </div>
      <div class="vx-col lg:w-1/4 w-full">
        <vs-input
          data-vv-validate-on="blur"
          name="LicenseNumber"
          :label="$t('LicenseNumber')"
          :placeholder="$t('LicenseNumber')"
          class="w-full"
          icon-no-border
          v-model="LicenseModel.Number"
          icon-after="true"
        />
      </div>
      <vs-button
        class="mt-5 vx-col lg:w-1/4"
        color="white"
        :disabled="!LicenseModel.Number || !LicenseModel.Country"
      >
        <feather-icon
          style="width: 50px; margin-left: 10px; color: black"
          icon="PlusIcon"
          @click.stop="AddLicenseNumber()"
        ></feather-icon>
      </vs-button>
    </div>

    <vs-row vs-w="12" class="mr-5 ml-5">
      <vs-col
        class="mt-2"
        vs-lg="6"
        vs-sm="12"
        v-for="(item, index) in doctorModel.DoctorLocalLicenseCountries"
        :key="item.ID"
      >
        <vs-row vs-w="12">
          <vs-col vs-lg="6" vs-sm="6">
            <u style="color: #454a62; font-size: 15px"
              >{{ item.Country.Name }} {{ item.Number }}</u
            >
          </vs-col>
          <vs-col vs-lg="3" vs-sm="3">
            <feather-icon
              style="color: black; width: 15px"
              icon="XIcon"
              @click.stop="deleteLicenseNumber(item, index)"
              class="cursor-pointer"
            >
            </feather-icon>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>

    <!-- ------------------------------------------------------------------------  -->
    <h3 style="color: #1f1f1f" class="mt-5">
      {{ $t("AdditionalInformation") }}
    </h3>
    <!--add Certificate -->

    <div class="vx-row w-full justify-center">
      <h3 style="color: #454a62; font-size: 15px" class="w-full sm:w-1/4 m-3 mt-6">
        {{ $t("AddCertificates") }}
      </h3>
      <vs-button color="wight" class="w-full sm:w-1/8 m-1 mt-1">
        <feather-icon
          style="color: black"
          icon="UploadIcon"
          @click.stop="ShowAddCertificate = true"
          class="cursor-pointer"
        ></feather-icon>
      </vs-button>
    </div>
    <!--show Certificate -->
    <vs-row vs-w="12">
      <vs-col
        vs-lg="6"
        vs-sm="12"
        v-for="(item, index) in doctorModel.DoctorExperiences"
        :key="item.ID"
      >
        <vs-row vs-w="12">
          <vs-col vs-lg="10" vs-sm="10">
            <u style="color: #454a62; font-size: 15px"
              >{{ item.TitleEN }}
              <a
                :href="baseURL + item.AttachPath"
                target="_blank"
                rel="nofollow"
                class="text-sm m-1"
                >download</a
              >
            </u>
          </vs-col>
          <vs-col vs-lg="2" vs-sm="2">
            <feather-icon
              style="color: black; width: 15px"
              icon="XIcon"
              @click.stop="deleteDoctorExperince(item, index)"
              class="cursor-pointer"
            >
            </feather-icon>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>

    <!-- add cv  -->
    <div class="vx-row w-full justify-center">
      <h3 style="color: #454a62; font-size: 15px" class="w-full sm:w-1/4 m-3 mt-6">
        {{ $t("AddCV") }}
      </h3>
      <vs-button color="wight" class="w-full sm:w-1/8 m-1 mt-1">
        <feather-icon
          style="color: black"
          icon="UploadIcon"
          @click.stop="ShowAddCv = true"
          class="cursor-pointer"
        ></feather-icon>
      </vs-button>
    </div>
    <!-- view cv -->
    <vs-row vs-w="12">
      <vs-col vs-lg="6" vs-sm="12">
        <vs-row vs-w="12">
          <vs-col vs-lg="10" vs-sm="10">
            <u style="color: #454a62; font-size: 15px"
              >{{ "C.V" }}
              <a
                :href="baseURL + doctorModel.CVPath"
                target="_blank"
                rel="nofollow"
                class="text-sm m-1"
                >download</a
              >
            </u>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>

    <div class="vx-row w-full mt-5">
      <label class="vs-input--label">{{ $t("GraduationDate") }}</label>
      <datepicker
        placeholder="GraduationDate"
        v-model="doctorModel.GraduationDate"
        class="w-full sm:w-1/4 sm:w-1/4 m-1 mt-1"
        name="GraduationDate"
      ></datepicker>
    </div>

    <div div class="vx-row w-full justify-center">
      <vs-input
        :label="$t('PlaceOfWork')"
        data-vv-validate-on="blur"
        name="PlaceOfWork"
        class="w-full"
        icon-no-border
        v-model="doctorModel.PlaceOfWork"
        icon-after="true"
      />
    </div>

    <div class="vx-row w-full mt-5">
      <label class="vs-input--label"> {{ $t("BiographyOptional") }} </label>

      <vs-textarea
        data-vv-validate-on="blur"
        name="Biography(Optional)"
        class="w-full"
        icon-no-border
        :placeholder="$t('Typeyourbiographyhere')"
        v-model="doctorModel.BioEN"
        icon-after="true"
      />
    </div>
    <!-- popup upload Certificate -->
    <vs-popup title="" :active.sync="ShowAddCertificate">
      <vx-card class="justify-center items-center" style="background-color: white">
        <vs-input
          class="w-full"
          :label="$t('TitleEN')"
          v-model="experienceModel.TitleEN"
          name="TitleEN"
        />

        <vs-input
          class="w-full mt-4"
          :label="$t('TitleAR')"
          v-model="experienceModel.TitleAR"
          name="TitleAR"
        />

        <vs-input
          :label="$t('ReferenceAreaEN')"
          class="w-full mt-4"
          v-model="experienceModel.ReferenceAreaEN"
          name="ReferenceAreaEN"
        />

        <vs-input
          :label="$t('ReferenceAreaAR')"
          class="w-full mt-4"
          v-model="experienceModel.ReferenceAreaAR"
          name="ReferenceAreaAR"
        />

        <div class="mt-5">
          <label for="from" class="vs-input--label"> {{ $t("FromDate") }}</label>
          <datepicker
            placeholder="from"
            v-model="experienceModel.DateFrom"
            class="vx-col w-full"
            name="from"
            v-validate="'required'"
          ></datepicker>
        </div>
        <div class="mt-5">
          <label for="to" class="vs-input--label"> To</label>
          <datepicker
            placeholder="to"
            v-model="experienceModel.DateTo"
            class="vx-col w-full"
            name="to"
            v-validate="'required'"
          ></datepicker>
        </div>

        <vs-card title="icon" class="mt-5">
          <h4>{{ $t("Image") }}</h4>
          <template v-if="experienceModel.AttachPath">
            <div class="img-container w-64 mx-auto flex items-center justify-center">
              <img
                :src="baseURL + experienceModel.AttachPath"
                alt="img"
                class="responsive"
              />
            </div>
          </template>
          <div class="modify-img flex justify-between mt-5">
            <input
              type="file"
              class="hidden"
              ref="updateImgInput"
              @change="updateCurrImgForCertificate"
              @on-success="successUpload"
              accept="image/*"
            />

            <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">{{
              $t("UpdateImage")
            }}</vs-button>
            <vs-button
              type="flat"
              color="#999"
              @click="experienceModel.AttachPath = null"
              >{{ $t("RemoveImage") }}</vs-button
            >
          </div>
        </vs-card>

        <vs-row
          vs-w="12"
          vs-justify="flex-end"
          class="w-full mt-2"
          style="text-algin: end"
        >
          <vs-col vs-lg="3" vs-sm="6">
            <vs-button
              class=""
              @click="(ShowAddCertificate = false), (experienceModel.AttachPath = null)"
              type="filled"
              >{{ $t("Back") }}</vs-button
            >
          </vs-col>
          <vs-col vs-lg="3" vs-sm="6">
            <vs-button
              class=""
              @click="saveExperienceModel()"
              type="filled"
              :disabled="addCer"
              >{{ $t("Add") }}</vs-button
            >
          </vs-col>
        </vs-row>
      </vx-card>
    </vs-popup>

    <!-- popup upload cv -->
    <vs-popup title="" :active.sync="ShowAddCv">
      <vx-card class="justify-center items-center" style="background-color: white">
        <vs-card title="icon" class="mt-5">
          <h4>{{ $t("Cv File") }}</h4>
          <template v-if="addCV">
            <div class="img-container w-64 mx-auto flex items-center justify-center">
              <a
                :href="baseURL + pathCv.path"
                target="_blank"
                rel="nofollow"
                class="text-sm m-1"
              >
                <h4>{{ $t("Cv File") }}</h4></a
              >
            </div>
          </template>
          <div class="modify-img flex justify-between mt-5">
            <input
              type="file"
              class="hidden"
              ref="updateCVInput"
              accept="application/pdf"
              @change="updateCurrImgForCV"
              @on-success="successUpload"
            />

            <vs-button class="mr-4" type="flat" @click="$refs.updateCVInput.click()">{{
              $t("Update CV")
            }}</vs-button>
            <vs-button type="flat" color="#999" @click="doctorModel.CVPath = null">{{
              $t("Remove CV")
            }}</vs-button>
          </div>
        </vs-card>
        <vs-row
          vs-w="12"
          vs-justify="flex-end"
          class="w-full mt-2"
          style="text-algin: end"
        >
          <!-- back -->
          <vs-col vs-lg="3" vs-sm="6">
            <vs-button class="" @click="ShowAddCv = false" type="filled">{{
              $t("Back")
            }}</vs-button>
          </vs-col>
          <!-- add -->
          <vs-col vs-lg="3" vs-sm="6">
            <vs-button class="" @click="savecvModel()" type="filled">{{
              $t("Add")
            }}</vs-button>
          </vs-col>
        </vs-row>
      </vx-card>
    </vs-popup>
  </div>
</template>
<script>
import axios from "@/axios.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
    // point
  },
  data() {
    return {
      experienceModel: {},
      pathCv: {},
      addCer: true,
      ShowAddCertificate: false,
      addCV: false,
      ShowAddCv: false,
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",

      LicenseModel: {},
      options: [],
      radios1: "luis",
    };
  },
  props: {
    doctorModel: {
      type: Object,
      required: true,
    },
  },
  methods: {
    AddLicenseNumber() {
      debugger;
      var model = {};
      model.CountryID = this.LicenseModel.Country.ID;
      model.Country = this.LicenseModel.Country;
      model.Number = this.LicenseModel.Number;
      if (this.$store.state.AppActiveUser.Doctor.ID > 0) {
        model.DoctorID = this.$store.state.AppActiveUser.Doctor.ID;
      } else {
        model.DoctorID = this.$route.params.ID;
      }
      this.doctorModel.DoctorLocalLicenseCountries.push(model);
      this.LicenseModel = {};
    },
    deleteLicenseNumber(data, index) {
      this.doctorModel.DoctorLocalLicenseCountries.splice(index, 1);
    },
    deleteDoctorExperince(data, index) {
      this.doctorModel.DoctorExperiences.splice(index, 1);
    },
    // ChangeRegion() {
    //   debugger;

    //   this.$store.dispatch(
    //     "RegionList/GetAllRegionsByCountryID",
    //     this.doctorModel.CountryID
    //   );
    // },

    // ChangeCities() {
    //   debugger;

    //   this.$store.dispatch(
    //     "CityList/GetAllCitiesByRegionID",
    //      this.doctorModel.RegionID
    //   );
    // },
    updateCurrImgForCertificate(input) {
      debugger;
      this.$store.dispatch("updateCurrImg", input).then((response) => {
        debugger;
        this.experienceModel.AttachPath = response.data;
        this.addCer = false;
        debugger;
      });
    },

    updateCurrImgForCV(input) {
      debugger;
      this.$store.dispatch("updateCurrImg", input).then((response) => {
        debugger;
        this.pathCv.path = response.data;
        this.addCV = true;
        debugger;
      });
    },
    saveExperienceModel() {
      debugger;
      if (this.experienceModel.AttachPath != null) {
        this.doctorModel.DoctorExperiences.push(this.experienceModel);

        this.clearExperienceModel();
      } else {
        this.$vs.notify({
          title: this.$t("Error"),
          text: "please upload the image",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      }
    },
    clearExperienceModel() {
      this.ShowAddCertificate = false;
      this.experienceModel = {};
      this.addCer = true;
    },

    savecvModel() {
      //this.ShowAddCv = false;
      debugger;
      if (this.pathCv != null) {
        this.doctorModel.CVPath = this.pathCv.path;
        //this.clearExperienceModel();
        this.ShowAddCv = false;
      } else {
        this.$vs.notify({
          title: this.$t("Error"),
          text: "please upload the image",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      }
    },
    changeMode() {
      this.doctorModel.Next = true;
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);

        axios.post(this.uploadUrl, data,{
          headers: {
              "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
          this.doctorModel.ProfileImagePath = response.data;
        });
      }
    },
  },
  computed: {
    specialities: {
      get() {
        return this.$store.state.DoctorList.specialities;
      },
      set(newValue) {
        this.$store.commit("DoctorList/SET_specialities", newValue);
      },
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
    ListOfSpecialities() {
      return this.$store.state.SpecialtyList.specialties;
    },
    // pathCVObj() {
    //   return this.$store.state.DoctorList.pathCV;
    // },
  },
  created() {
    this.doctorModel.CVPath;
    debugger;
  },
};
</script>
<style>
.circle {
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 50%;
}
.textfont {
  font-family: "futuraMedium";
}
</style>
