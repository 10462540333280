<template>
  <div class="w-full justify-center">
    <div class="vx-row w-full con-img justify-center">
      <img
        v-if="doctorModel.ProfileImagePath"
        :src="baseURL + doctorModel.ProfileImagePath"
        alt="user-img"
        width="70"
        height="70"
        class="rounded-full shadow-md cursor-pointer block m-4"
      />
      <img
        v-else
        :src="baseURL + 'Uploads/docotrGeneral.jpg'"
        alt="user-img"
        width="70"
        height="70"
        class="rounded-full shadow-md cursor-pointer block m-4"
      />
      <div class="position: absolute py-12 w-1">
        <input
          type="file"
          class="hidden"
          ref="uploadImgInput"
          @change="updateCurrImg"
          accept="image/*"
          @on-success="successUpload"
        />

        <vs-avatar
          @click="$refs.uploadImgInput.click()"
          color="primary"
          icon-pack="feather"
          icon="icon-upload"
          class="m-5"
        />
      </div>
    </div>

    <div class="vx-row w-full justify-center mt-5">
      <!-- name -->
      <div class="vx-col lg:w-1/2 w-full">
        <p class="vs-input--label" style="color: #454a62; font-size: 15px">
          {{ $t("FullName") }}*
        </p>
        <vs-input
          data-vv-validate-on="blur"
          name="name"
          icon-pack="feather"
          v-model="doctorModel.NameEN"
          :icon-after="true"
          class="w-full mt-3"
        />
      </div>
      <!-- FamilyName -->
      <div class="vx-col lg:w-1/2 w-full">
        <p class="vs-input--label" style="color: #454a62; font-size: 15px">
          {{ $t("FamilyName") }} *
        </p>
        <vs-input
          data-vv-validate-on="blur"
          name="FamilyName"
          class="w-full m-1 mt-3"
          icon-no-border
          v-model="doctorModel.FamilyName"
          :icon-after="true"
        />
      </div>
    </div>

    <div div class="vx-row w-full justify-center mt-4">
      <!-- country -->
      <div class="vx-col lg:w-1/3 w-full">
        <label class="vs-input--label">{{ $t("Country") }}*</label>
        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          data-vv-validate-on="blur"
          label="Name"
          name="Country"
          class="w-full"
          :options="countries"
          v-model="doctorModel.CountryID"
          :icon-after="true"
          :reduce="(ID) => ID.ID"
          @input="onSelectCountry()"
        />
      </div>
      <!-- regions -->
      <div class="vx-col lg:w-1/3 w-full">
        <label class="vs-input--label">{{ $t("Region") }}*</label>
        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          data-vv-validate-on="blur"
          label="Name"
          name="Region"
          label-placeholder="Region"
          :placeholder="$t('Region')"
          class="w-full"
          v-model="doctorModel.RegionID"
          :options="regions"
          :icon-after="true"
          :reduce="(ID) => ID.ID"
          @input="ChangeCities()"
        />
      </div>
      <!-- City -->
      <div class="vx-col lg:w-1/3 w-full">
        <label class="vs-input--label">{{ $t("City") }}*</label>

        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          data-vv-validate-on="blur"
          label="Name"
          name="City"
          class="w-full"
          v-model="doctorModel.CityID"
          :options="cities"
          label-placeholder="City"
          :placeholder="$t('City')"
          :icon-after="true"
          :reduce="(ID) => ID.ID"
        />
      </div>
    </div>

    <div class="vx-row w-full mt-5 justify-center">
      <div class="vx-col lg:w-1/2 w-full mt-5">
            <VuePhoneNumberInput :placeholder="$t('PhoneNumber')" :default-country-code="defaultPhoneData.countryCode" @update="acceptNumber" v-model="doctorModel.Phone" />

        <!-- <vs-input
          data-vv-validate-on="blur"
          name="Phone"
          class="w-full m-1 mt-1"
          :label="$t('PhoneNumber')"
          icon-no-border
          @keyup="checkDailCod()"
          v-model="doctorModel.Phone"
          :icon-after="true"
        /> -->
      </div>

      <div class="vx-col lg:w-1/2 w-full">
        <label class="vs-input--label">{{ $t("PreferredLanguage") }}*</label>
        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          data-vv-validate-on="blur"
          label="Name"
          name="language"
          class="w-full"
          multiple
          v-model="Langauges"
          :options="ListOfLanguages"
          :reduce="(ID) => ID.ID"
          label-placeholder="PreferredLanguage"
          :placeholder="$t('PreferredLanguage')"
          :icon-after="true"
        />
      </div>
    </div>

    <div div class="vx-row w-full mt-5 justify-center">
      <p class="mt-1">{{ $t("Gender") }}*</p>

      <vs-radio class="m-2" v-model="doctorModel.GenderID" :vs-value="2">{{
        $t("male")
      }}</vs-radio>
      <vs-radio v-model="doctorModel.GenderID" :vs-value="1">{{
        $t("female")
      }}</vs-radio>
    </div>
    <div>
      <p class="mt-1" style="color: #454a62; font-size: 15px">
        {{ $t("Speciality") }}*
      </p>

      <div div class="w-full">
        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          data-vv-validate-on="blur"
          label="Name"
          name="Speciality"
          class="w-full mt-1"
          multiple
          v-model="specialities"
          :options="ListOfSpecialities"
          :reduce="(ID) => ID.ID"
        />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/axios.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import moduleCity from "@/store/settings/city/moduleCity.js";
import moduleRegion from "@/store/settings/region/moduleRegion.js";
import moduleLanguage from "@/store/settings/language/moduleLanguage.js";
import VuePhoneNumberInput from 'vue-phone-number-input';

export default {
  components: {
    VuePhoneNumberInput
    // point
  },
  data() {
    return {
      phonevalue:'',
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      // doctorModel:{},
      options: [],
      radios1: "luis",
      defaultPhoneData:{
        countryCode : ""
      }

    };
  },
  props: {
    doctorModel: {
      type: Object,
      required: true,
    },
  },
  methods: {

  //   acceptNumber(value) {
  // this.doctorModel.phonevalue = value.e164;
  //  //this.doctorModel.CountryID = this.countries.find((x) => x.IsoCode === value.countryCode).ID
  //  this.countries.find((x) => x.ID === this.doctorModel.CountryID).countryCode;
  //   },
  acceptNumber(value)
  {
    debugger;

    if (this.doctorModel.CountryID !=null || this.doctorModel.CurrentUSerCountryID)
    {
      var country = this.countries.find((x) => (x.ID === this.doctorModel.CountryID ||x.ID=== this.doctorModel.CurrentUSerCountryID));
      this.defaultPhoneData.countryCode =country.IsoCode;
      value.countryCode=this.defaultPhoneData.countryCode;
      this.phonevalue = value.e164;
      this.doctorModel.phone=this.phonevalue;
    }
    // this.phonevalue = value.e164;
    // this.defaultCountryCode.countryCode;
    },
    changeMode()
    {
      debugger;
      this.doctorModel.Next = true;
    },

    onSelectCountry() {
      debugger;

      var country = this.countries.find((x) => x.ID === this.doctorModel.CountryID);
      this.defaultPhoneData.countryCode =country.IsoCode;
      //   "+" +
      //   this.countries.find((x) => x.ID === this.doctorModel.CountryID)
      //     .DailCode;
      // debugger;

      this.$store
        .dispatch(
          "RegionList/GetAllRegionsByCountryID",
          this.doctorModel.CountryID
        )
        .then((res) => {
          if (res.status == 200) {
            this.doctorModel.RegionID = null;
              this.doctorModel.CityID = null;
          }
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },

    ChangeCities() {
      debugger;
      this.$store
        .dispatch("CityList/GetAllCitiesByRegionID", this.doctorModel.RegionID)
        .then((res) => {
          if (res.status == 200) {
            this.doctorModel.CityID = null;
          }
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    checkDailCod() {
      debugger;
      if (
        this.doctorModel.Phone.length <=
        this.countries.find((x) => x.ID === this.doctorModel.CountryID).DailCode
          .length
      ) {
        this.doctorModel.Phone =
          "+" +
          this.countries.find((x) => x.ID === this.doctorModel.CountryID)
            .DailCode;
      }
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);

        axios.post(this.uploadUrl, data,{
          headers: {
              "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
          this.doctorModel.ProfileImagePath = response.data;
        });
      }
    },
  },
  computed: {
    cities(){
        return this.$store.state.CityList.cities;
    },
  regions(){
        return this.$store.state.RegionList.regions;
    },
    regions(){
        return this.$store.state.RegionList.regions;
    },
    Langauges: {
      get() {
        return this.$store.state.DoctorList.Langauges;
      },
      set(newValue) {
        this.$store.commit("DoctorList/SET_DoctorLanguages", newValue);
      },
    },
    // cities() {
    //   debugger;
    //   if (this.$store.state.CityList.cities)
    //     return this.$store.state.CityList.cities;
    //   else return [{}];
    // },
    // regions() {
    //   return this.$store.state.RegionList.regions;
    // },
    specialities: {
      get() {
        return this.$store.state.DoctorList.specialities;
      },
      set(newValue) {
        this.$store.commit("DoctorList/SET_specialities", newValue);
      },
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
    ListOfSpecialities() {
      debugger;
      return this.$store.state.SpecialtyList.specialties;
    },
    ListOfLanguages() {
      debugger;
      return this.$store.state.LanguageList.languages;
    },
  },
  created() {
    debugger;
    if (!moduleLanguage.isRegistered) {
      this.$store.registerModule("LanguageList", moduleLanguage);
      moduleLanguage.isRegistered = true;
    }

    this.$store.dispatch("LanguageList/GetAllLanguages");
    if (!moduleCity.isRegistered) {
      this.$store.registerModule("CityList", moduleCity);
      moduleCity.isRegistered = true;
    }
    if (!moduleRegion.isRegistered) {
      this.$store.registerModule("RegionList", moduleRegion);
      moduleRegion.isRegistered = true;
    }


  },
};
</script>
<style>
</style>
